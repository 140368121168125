<template>
  <div style="margin-top: 0px">
    <!---
    // Grid view
    ---->
    <v-form ref="formsearch" v-model="validform" class="pt-0" lazy-validation>
      <div style="max-width: 600px; margin: 0 auto">
        <div id="googmap"></div>
        <!---
        <v-alert
          v-if="!selectedParcel || !selectedAddress || !selectedBathrooms || !selectedBedrooms || !selectedDistance"
          type="warning"
          colored-border
          border="bottom"
          elevation="2"
          >Try adjusting the options to list comps
        </v-alert>
        <v-alert
          v-if="selectedParcel && selectedAddress && selectedBathrooms && selectedBedrooms && selectedDistance"
          type="success"
          colored-border
          border="bottom"
          elevation="2"
          >Looks like you're ready
        </v-alert>
        --->
        <v-row style="max-width: 600px" class="pt-0">
          <v-col class="pt-0">
            <v-text-field
              v-model="selectedSearchTerm"
              style="border: 2px solid #e55e5e"
              class="mb-0 pb-0 mt-0 pt-0 pl-0 pr-0"
              label="Enter a location"
              append-icon="mdi-magnify"
              hide-details
              outlined
              clearable
              dense
              @keyup="searchPlaces()"
            >
            </v-text-field>
            <v-card
              v-if="searchTermQuickSearch.length > 0"
              v-click-outside="onClickOutsideSearchTerm"
              style="overflow-y: scroll; height: 200px; margin-left: 20px; position: absolute; z-index: 99"
            >
              <v-card
                v-for="(field, i) in searchTermQuickSearch"
                :key="i"
                style="cursor: pointer; margin-bottom: 2px"
                @click="
                  selectedName = field.name;
                  selectedAddress = field.formatted_address;
                  selectedCoordinates = {
                    lat: field.geometry.location.lat(),
                    lng: field.geometry.location.lng(),
                  };
                  onClickSearchTerm();
                  onClickOutsideSearchTerm();
                "
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-title">
                      {{ field.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ field.formatted_address }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-card>
          </v-col>
          <v-col v-if="selectedAddress" class="pt-0">
            <v-card style="cursor: pointer; margin-bottom: 2px" @click="clearSearch()">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-title">
                    {{ selectedName }} <span style="float: right; font-weight: bold">x</span>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ selectedAddress }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
          <!---
          <v-col>
            <v-text-field
              v-model="selectedParcel"
              class="mb-0 pb-0 mt-0 pt-0 pl-5 pr-5"
              label="Enter Parcel"
              append-icon="mdi-magnify"
              hide-details
              outlined
              clearable
              dense
              @keyup="searchAllPropertiesByParcel()"
            >
            </v-text-field>
            <v-card
              v-if="propertyParcelQuickSearch.length > 0"
              v-click-outside="onClickOutsideParcelSearch"
              style="
                overflow-y: scroll;
                height: 400px;
                margin-left: 20px;
                position: absolute;
                z-index: 99;
                width: 220px;
              "
            >
              <v-card
                v-for="(field, i) in propertyParcelQuickSearch"
                :key="i"
                style="cursor: pointer; margin-bottom: 2px"
                @click="
                  selectedAddress = field.address;
                  selectedParcel = field.parcelnumb;
                  onClickOutsideParcelSearch();
                "
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-title">
                      {{ field.parcelnumb }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ field.owner }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ field.address }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-card>
          </v-col>
          --->
          <!---
          <span class="font-weight-medium" style="margin-top: 20px">or</span>
          <v-col>
            <v-text-field
              v-model="selectedAddress"
              class="mb-0 pb-0 mt-0 pt-0 pl-5 pr-5"
              label="Enter Address"
              append-icon="mdi-magnify"
              hide-details
              outlined
              clearable
              dense
              @keyup="searchAllPropertiesByAddress()"
            >
            </v-text-field>
            <v-card
              v-if="propertyAddressQuickSearch.length > 0"
              v-click-outside="onClickOutsideAddressSearch"
              style="
                overflow-y: scroll;
                height: 400px;
                margin-left: 20px;
                position: absolute;
                z-index: 99;
                width: 220px;
              "
            >
              <v-card
                v-for="(field, i) in propertyAddressQuickSearch"
                :key="i + 1000"
                style="cursor: pointer; margin-bottom: 2px"
                @click="
                  selectedAddress = field.address;
                  selectedParcel = field.parcelnumb;
                  onClickOutsideAddressSearch();
                "
              >
                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-title">
                      {{ field.parcelnumb }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ field.owner }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      {{ field.address }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </v-card>
          </v-col>
          --->
        </v-row>
        <v-row class="pt-0" style="max-width: 600px">
          <v-col class="pt-0">
            <v-text-field
              v-model="selectedDistance"
              dense
              label="Radius miles"
              outlined
              type="number"
              required
              hide-details="auto"
              @change="onClickSearchTerm"
            />
          </v-col>
          <v-col class="pl-0 pt-0">
            <v-select
              v-model="selectedBedrooms"
              :items="selectBedrooms"
              dense
              outlined
              required
              :rules="[(v) => !!v || 'Item is required']"
              label="Beds"
              hide-details="auto"
              @change="filterBedrooms"
            ></v-select>
          </v-col>
          <v-col class="pl-0 pt-0">
            <v-select
              v-model="selectedBathrooms"
              :items="selectBathrooms"
              dense
              outlined
              :rules="[(v) => !!v || 'Item is required']"
              label="Baths"
              required
              hide-details="auto"
              @change="filterBathrooms"
            ></v-select>
          </v-col>
          <v-col class="pt-0 pl-0 d-flex justify-center">
            <v-btn v-if="isMobile()" :disabled="!selectedAddress" class="ml-0 pa-2 accent" medium @click="validate"
              >Search</v-btn
            >
            <v-btn v-if="!isMobile()" :disabled="!selectedAddress" class="ml-0 pa-2 accent" medium @click="validate"
              >Show Comps</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-form>
    <div style="position: relative">
      <div>
        <!----
        // Map view
        ------>
        <v-row>
          <v-col class="pt-0">
            <v-row>
              <v-col style="padding-top: 0px">
                <v-progress-linear
                  :style="{ visibility: loadingPropertiesGeo | loadingNeighborhoodCluster ? 'visible' : 'hidden' }"
                  indeterminate
                ></v-progress-linear>
                <!---
                // Map container
                ---->
                <div id="mapdivcluster" style="width: 100%; height: 620px"></div>
                <!---
                // Map legend
                --->
                <div class="cus-legend">
                  <div class="cus-legend-title">{{ selectedRateLegend.title }}</div>
                  <div class="cus-legend-colorbar">
                    <div class="cus-legend-text" style="float: left">Low</div>
                    <div class="cus-legend-green"></div>
                    <div class="cus-legend-yellow"></div>
                    <div class="cus-legend-red"></div>
                    <div class="cus-legend-text" style="float: left">High</div>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </div>
    <str-property-comps-detail />
  </div>
</template>

<script>
import numeral from 'numeral';
import { mapGetters } from 'vuex';
import mapboxgl from 'mapbox-gl';
//import PropertiesService from '@/services/properties.service';
import StrPropertyCompsDetail from '@/components/StrPropertyCompsDetail';
import NeighborhoodsService from '@/services/neighborhoods.service';
import StrsService from '@/services/strs.service';
export default {
  name: 'STRGrid',
  components: { StrPropertyCompsDetail },
  data() {
    return {
      selectedSearchTerm: '',
      searchTermQuickSearch: [],
      textSearchTimer: '',
      validform: true,
      numeral,
      loadingPropertiesList: false,
      activeParcel: null,
      propertiesList: [],
      propertiesFilterCount: 0,
      propertyDetail: null,
      searchPropertiesText: '',
      search: '',
      headers: [
        { text: 'Permit', value: 'permit_status' },
        { text: 'Permit Description', value: 'permit_subtype_description' },
        { text: 'Parcel', value: 'parcelnumb' },
        { text: 'Address', value: 'address' },
        { text: 'City', value: 'city' },
        { text: 'Zip', value: 'zip' },
        { text: 'Zone', value: 'zoning' },
        { text: 'Land Use', value: 'usedesc' },
      ],
      tableKey: '1',
      searchPropertyListText: null,
      pageNumProperties: 1,
      pagingPropertiesPerPage: 40,
      selectedPermitType: '',
      selectedZip: '',
      selectedLandUse: '',
      selectedZone: '',
      selectedPermitStatus: '',
      addParcelText: '',
      selectedBathrooms: '',
      selectBathrooms: [
        { text: '1+', value: '1' },
        { text: '2+', value: '2' },
        { text: '3+', value: '3' },
        { text: '4+', value: '4' },
      ],
      selectedBedrooms: '',
      selectBedrooms: [
        { text: '1', value: '1' },
        { text: '2', value: '2' },
        { text: '3', value: '3' },
        { text: '4', value: '4' },
      ],
      selectedDistance: 0.8,
      selectedParcel: '',
      selectedAddress: '',
      selectedCoordinates: '',
      propertyParcelQuickSearch: [],
      propertyAddressQuickSearch: [],
      neighborhoods: [],
      neighborhoodId: '',
      neighborhoodGeos: [],
      loadingNeighborhoodCluster: false,
      markersOnScreen: {},
      markers: {},
      colors: ['#e55e5e', '#fbb03b', '#3bb2d0'],
      loadingPropertiesGeo: false,
      mapType: 'dark',
      mapTile: 'light-v10',
      selectedRateLegend: {
        abbr: 'ocr',
        title: 'Occupancy Rate',
        index: 'occupancyRate_index',
        value: 'occupancyRateRate',
      },
      rateChoices: [
        {
          abbr: 'anr',
          title: 'Average Nightly Rate',
          index: 'publishNightlyRate_index',
          value: 'publishNightlyRate',
        },
        {
          abbr: 'ocr',
          title: 'Occupancy Rate',
          index: 'occupancyRate_index',
          value: 'occupancyRateRate',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      activeGeoId: 'getActiveGeoId',
    }),
    quickSearchProperties() {
      var resResult = this.propertiesList;
      // Search Text
      if (this.searchPropertyListText) {
        resResult = resResult.filter((item) => {
          return this.searchPropertyListText
            .toLowerCase()
            .split(' ')
            .every((v) => item.parcelnumb.toLowerCase().includes(v));
        });
      }
      // Selected Permit Type
      if (this.selectedPermitType) {
        resResult = resResult.filter((item) => {
          return this.selectedPermitType == item.permit_subtype_description;
        });
      }
      // Selected Permit Status
      if (this.selectedPermitStatus) {
        resResult = resResult.filter((item) => {
          return this.selectedPermitStatus == item.permit_status;
        });
      }
      // Selected Zipcode
      if (this.selectedZip) {
        resResult = resResult.filter((item) => {
          return (v) => this.selectedZip == item.zip.includes(v);
        });
      }
      // Selected Land use
      if (this.selectedLandUse) {
        resResult = resResult.filter((item) => {
          return (v) => this.selectedLandUse == item.usedesc.includes(v);
        });
      }
      // Selected Zones
      if (this.selectedZone) {
        resResult = resResult.filter((item) => {
          return (v) => this.selectedZone == item.zoning.includes(v);
        });
      }
      return resResult;
    },
    distinctPermitType() {
      return [...new Set(this.propertiesList.map((x) => x.permit_subtype_description))];
    },
    distinctPermitStatus() {
      return [...new Set(this.propertiesList.map((x) => x.permit_status))];
    },
    distinctZip() {
      return [...new Set(this.propertiesList.map((x) => x.zip))];
    },
    distinctLandUse() {
      return [...new Set(this.propertiesList.map((x) => x.usedesc))];
    },
    distinctZone() {
      return [...new Set(this.propertiesList.map((x) => x.zoning))];
    },
  },
  watch: {
    activeGeoId() {
      this.loadMap();
    },
  },
  mounted() {
    this.selectedRateLegend = this.rateChoices.filter((item) => {
      return item.abbr === 'ocr';
    })[0];
    this.getNeighborhoods();
    window.checkAndAttachMapScript(this.searchPlaces);
  },
  methods: {
    validate() {
      if (this.$refs.formsearch.validate() == true) {
        this.getComps();
      }
    },
    setGeoId(geoId) {
      this.$store.commit('setGeoId', { id: geoId });
    },
    onClickOutsideParcelSearch() {
      this.propertyParcelQuickSearch = [];
    },
    onClickOutsideAddressSearch() {
      this.propertyAddressQuickSearch = [];
    },
    onClickOutsideSearchTerm() {
      this.searchTermQuickSearch = [];
      this.selectedSearchTerm = '';
    },
    onClickSearchTerm() {
      var searchRadiusOverlayMapLayer = this.map.getLayer('searchRadius');
      if (typeof searchRadiusOverlayMapLayer !== 'undefined') {
        this.map.removeLayer('searchRadius');
        this.map.removeSource('searchRadius-Data');
      }

      this.map.addSource(
        'searchRadius-Data',
        this.createGeoJSONCircle(
          [this.selectedCoordinates.lng, this.selectedCoordinates.lat],
          this.selectedDistance * 1.60934,
        ),
      );
      this.map.addLayer({
        id: 'searchRadius',
        type: 'fill',
        source: 'searchRadius-Data',
        layout: {},
        paint: {
          'fill-color': 'black',
          'fill-opacity': 0.3,
        },
      });
      new mapboxgl.Marker({ color: '#e31e26' })
        .setLngLat([this.selectedCoordinates.lng, this.selectedCoordinates.lat])
        .addTo(this.map);
      this.map.flyTo({ center: [this.selectedCoordinates.lng, this.selectedCoordinates.lat], zoom: 14 });
    },
    filterBedrooms() {
      if (this.selectedBathrooms) {
        this.map.setFilter('population', [
          'all',
          ['==', ['get', 'bedrooms'], this.selectedBedrooms],
          ['>=', ['get', 'bathrooms'], this.selectedBathrooms],
        ]);
      } else {
        this.map.setFilter('population', ['==', ['get', 'bedrooms'], this.selectedBedrooms]);
      }
    },
    filterBathrooms() {
      if (this.selectedBedrooms) {
        this.map.setFilter('population', [
          'all',
          ['==', ['get', 'bedrooms'], this.selectedBedrooms],
          ['>=', ['get', 'bathrooms'], this.selectedBathrooms],
        ]);
      } else {
        this.map.setFilter('population', ['>=', ['get', 'bathrooms'], this.selectedBathrooms]);
      }
    },
    /*
    searchAllPropertiesByParcel() {
      PropertiesService.searchPropertiesByParcel({
        limit: 10,
        parcel_num: this.selectedParcel,
        geo_id: this.$store.state.activeGeoId,
      })
        .then((response) => {
          this.propertyParcelQuickSearch = response.data;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => (this.loadingFilters = false));
    },
    searchAllPropertiesByAddress() {
      PropertiesService.searchPropertiesByAddress({
        limit: 10,
        address: this.selectedAddress,
        geo_id: this.$store.state.activeGeoId,
      })
        .then((response) => {
          this.propertyAddressQuickSearch = response.data;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => (this.loadingFilters = false));
    },
    */
    // Acre formatting
    formatAcre(val) {
      if (val >= this.acreMax) {
        return this.numeral(val).format('0.00') + '+';
      } else {
        return this.numeral(val).format('0.00');
      }
    },
    // Currency formatting
    formatCurrency(value) {
      return Number(parseFloat(value)).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    /*
    // used in data table for click event
    handleDataTableClick(event) {
      var response = event;
      this.$store.commit('setStrDetail', response);
      this.$store.commit('showStrDetail', true);
    },
    */
    // used in data table for click event
    getComps() {
      this.$store.commit('showStrCompsDetail', true);
      this.$store.commit('setStrCompSearchData', {
        address: this.selectedAddress,
        longlat: this.selectedCoordinates,
        bathrooms: this.selectedBathrooms,
        bedrooms: this.selectedBedrooms,
        distance: this.selectedDistance,
      });
    },
    parseContactdate(str) {
      if (!/^(\d){8}$/.test(str)) return 'invalid date';
      var y = str.substr(0, 4),
        m = str.substr(4, 2),
        d = str.substr(6, 2);

      var ddate = new Date(y, m, d);
      return ddate.getMonth() + 1 + '/' + ddate.getDate() + '/' + ddate.getFullYear();
    },
    loadMap() {
      var vm = this;
      mapboxgl.accessToken =
        'pk.eyJ1IjoiY2hhcG1hc3dlbXBvd2VyIiwiYSI6ImNraDZ0Z2M5eDAyY2kyeGsxYno1enU1MGgifQ.P6K0qDTB7-ccH0OaD4K2hg';
      // Init map settings
      this.map = new mapboxgl.Map({
        container: 'mapdivcluster',
        style: 'mapbox://styles/mapbox/' + this.mapTile + '',
        center: this.$store.state.activeGeoLongLat,
        zoom: 13,
      });

      // no scroll zoom on mobile
      if (this.isMobile()) {
        this.map.scrollZoom.disable();
        this.map.dragPan.disable();
      }
      // Add map controls
      this.map.addControl(new mapboxgl.NavigationControl());
      // On map load event
      this.map.on('load', function () {
        // init map parcel data
        vm.searchProperties();
      });
    },
    // Get all neighborhoods
    async getNeighborhoods() {
      NeighborhoodsService.getNeighborhoods()
        .then((response) => {
          this.neighborhoods = response.data;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => (this.errored = false));
    },
    // Select the neighborhood
    selectNeighborhood() {
      let neighborhood = this.neighborhoods.filter((item) => {
        return parseInt(item.id) === parseInt(this.neighborhoodId);
      });

      var poly = [];
      for (var i = 0; i < neighborhood[0].geopolygon[0].length; i++) {
        poly.push([neighborhood[0].geopolygon[0][i].x, neighborhood[0].geopolygon[0][i].y]);
      }

      var districtOverlayMapLayer = this.map.getLayer('district-fill');
      if (typeof districtOverlayMapLayer !== 'undefined') {
        this.map.removeLayer('district-fill');
        this.map.removeLayer('district-outline');
        this.map.removeSource('district-data');
      }

      this.map.addSource('district-data', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [poly],
          },
        },
      });
      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: 'district-fill',
        type: 'fill',
        source: 'district-data', // reference the data source
        layout: {},
        paint: {
          'fill-color': '#223b53', // blue color fill
          'fill-opacity': 0.1,
        },
      });
      this.map.addLayer({
        id: 'district-outline',
        type: 'line',
        source: 'district-data',
        layout: {},
        paint: {
          'line-color': '#223b53',
          'line-width': 1,
        },
      });

      var longlat = [parseFloat(neighborhood[0].lng), parseFloat(neighborhood[0].lat)];
      this.$store.commit('setNeighborhood', neighborhood[0]);
      this.map.flyTo({ center: longlat, zoom: 13 });
    },
    refreshData() {
      this.searchProperties();
      // may not want to call this twice
      //this.getClusterNeighborhoods();
    },
    // main search properties method
    searchProperties() {
      // exit if zoomed out and only show clusters
      // turn back on if not showing dots
      if (this.map.getZoom() <= this.maxZoom) {
        // return;
      }

      // only get map geo for map view
      this.updateMapParcels();
      this.loadingPropertiesList = true;
      StrsService.getStrs()
        .then((response) => {
          this.propertiesList = response.data;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => {
          this.loadingPropertiesList = false;
          this.tableKey = '12';
        });
    },
    // update map with new data as the user moves the map
    updateMapParcels() {
      var vm = this;
      vm.loadingPropertiesGeo = true;
      vm.propertiesGeo = [];

      // Get str geo placements
      StrsService.getStrsGeo()
        .then((response) => {
          vm.propertiesGeo = response.data;
          vm.propertiesMapCount = vm.propertiesGeo.length;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
          this.loadingPropertiesGeo = false;
        })
        .finally(() => {
          vm.map.addSource('earthquakes', {
            type: 'geojson',
            data: vm.propertiesGeo,
          });
          vm.map.addLayer({
            id: 'population',
            type: 'circle',
            source: 'earthquakes',
            paint: {
              // Make circles larger as the user zooms from z12 to z22.
              'circle-radius': {
                base: 1.5,
                stops: [
                  [12, 2],
                  [22, 180],
                ],
              },
              // Color circles by ethnicity, using a `match` expression.
              'circle-color': [
                'match',
                ['get', vm.selectedRateLegend.index],
                1,
                '#e55e5e',
                2,
                '#fbb03b',
                3,
                '#3bb2d0',
                4,
                '#3bb2d0',
                '#ccc',
              ],
            },
          });
          this.loadingPropertiesGeo = false;
        });
    },
    searchPlaces() {
      if (this.selectedSearchTerm.length > 3) {
        clearTimeout(this.textSearchTimer);
        this.textSearchTimer = setTimeout(this.searchPlacesAllExe(), 3000);
      }
    },
    searchPlacesAllExe() {
      var map = new window.google.maps.Map(document.getElementById('googmap'));
      let searchservice = new window.google.maps.places.PlacesService(map);
      var request = {
        query: this.selectedSearchTerm,
        fields: ['name', 'formatted_address', 'photos', 'geometry'],
        locationBias: { radius: 25000, center: { lat: 36.102, lng: -86.464 } },
      };
      searchservice.findPlaceFromQuery(request, (results, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          console.log(results);
          this.searchTermQuickSearch = results;
        }
      });
    },
    clearSearch() {
      this.selectedName = '';
      this.selectedAddress = '';
    },
    createGeoJSONCircle(center, radiusInKm, points) {
      if (!points) {
        points = 64;
      }

      var coords = {
        latitude: center[1],
        longitude: center[0],
      };

      var km = radiusInKm;

      var ret = [];
      var distanceX = km / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
      var distanceY = km / 110.574;

      var theta, x, y;
      for (var i = 0; i < points; i++) {
        theta = (i / points) * (2 * Math.PI);
        x = distanceX * Math.cos(theta);
        y = distanceY * Math.sin(theta);

        ret.push([coords.longitude + x, coords.latitude + y]);
      }
      ret.push(ret[0]);

      return {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: { type: 'Polygon', coordinates: [ret] },
        },
      };
    },
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a,
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4),
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    /*
      // Autocomplete place search
      searchPlaces() {
      let autocomplete = new window.google.maps.places.Autocomplete(this.$refs.search);
      // autocomplete.setBounds(new window.google.maps.LatLng(lat, lng, latnorth, lngeast));
      autocomplete.addListener('place_changed', function () {
        let place = autocomplete.getPlace();
        if (place && place.geometry) {
          console.log(place.geometry.location.lng());
          console.log(place.geometry.location.lat());
        }
      });
    },
    */
  },
};
// Add google map script if not exist; if already exist, return true
window.checkAndAttachMapScript = function (callback) {
  let scriptId = 'map-api-script';
  let mapAlreadyAttached = !!document.getElementById(scriptId);

  if (mapAlreadyAttached) {
    if (window.google)
      // Script attached but may not finished loading; so check for 'google' object.
      callback();
  } else {
    window.mapApiInitialized = callback;

    let script = document.createElement('script');
    script.id = scriptId;
    script.src =
      'https://maps.googleapis.com/maps/api/js?v=beta&key=AIzaSyAhIZPRZaKCdZYd9_ao6lJ9quSyRNF4TKw&libraries=places,geometry&callback=mapApiInitialized';
    document.body.appendChild(script);
  }
  return mapAlreadyAttached;
};
</script>
<style>
.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-attrib-inner {
  display: none !important;
}
</style>
<style scoped>
.text-sm-body-custom {
  font-size: 0.775rem;
  line-height: 14px;
}
.cus-legend-title {
  font-size: 0.9em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 6px;
}
.cus-legend-colorbar {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  overflow: hidden;
}
.cus-legend-text {
  color: white;
  font-weight: bold;
  font-size: 0.85em;
  padding: 5px;
}
.cus-legend-green,
.cus-legend-yellow,
.cus-legend-red {
  margin: 5px;
  width: 20px;
  height: 20px;
  background: #3bb2d0;
  float: left;
  border: 1px solid #eaeaea;
}
.cus-legend-yellow {
  background: #fbb03b;
}
.cus-legend-red {
  background: #e55e5e;
}
.cus-legend {
  position: absolute;
  top: 5px;
  right: 80px;
  z-index: 1;
}
@media only screen and (max-width: 600px) {
  .cus-legend {
    right: 0px;
    left: 0px;
    width: 180px;
    margin: 0 auto;
  }
}
</style>
