<template>
  <div style="margin-top: 0px; position: relative">
    <h2 style="color: rgba(0, 0, 0, 0.87)">{{ selectedRateLegend.title }}</h2>
    <v-row>
      <v-col>
        <h3 style="color: #bbbbbb">Davidson County, TN</h3>
      </v-col>
      <v-col>
        <div style="width: 250px">
          <v-select
            v-model="neighborhoodId"
            width="200px"
            class="mt-0"
            :items="neighborhoods"
            dense
            item-text="name"
            item-value="id"
            outlined
            label="Select a district"
            @input="selectNeighborhood"
          >
          </v-select>
        </div>
      </v-col>
    </v-row>
    <div style="margin-top: -20px">
      <str-detail />
      <!----
      // Map view
      ------>
      <v-row>
        <v-col class="pt-0">
          <v-row>
            <v-col style="padding-top: 0px">
              <v-progress-linear
                :style="{ visibility: loadingPropertiesGeo | loadingNeighborhoodCluster ? 'visible' : 'hidden' }"
                indeterminate
              ></v-progress-linear>
              <!---
              // Map container
              ---->
              <div id="mapdiv" style="width: 100%; height: 620px"></div>
              <!---
              // Map legend
              --->
              <div class="cus-legend">
                <div class="cus-legend-title">{{ selectedRateLegend.title }}</div>
                <div class="cus-legend-colorbar">
                  <div class="cus-legend-text" style="float: left">Low</div>
                  <div class="cus-legend-green"></div>
                  <div class="cus-legend-yellow"></div>
                  <div class="cus-legend-red"></div>
                  <div class="cus-legend-text" style="float: left">High</div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!---
      <div
        class="secondary"
        style="
          position: absolute;
          bottom: 40px;
          z-index: 4;
          padding: 5px;
          width: 100%;
          border: 1px grey solid;
          border-right: none;
          border-left: none;
        "
      >
        <v-row justify="center">
          <v-col style="text-align: right; margin-top: 10px">
            <h4 style="color: #ffffff">
              Need <u>Custom Data?</u>
              Connect with our team!
            </h4>
          </v-col>
          <v-col>
            <v-btn elevation="2" class="primary" href="https://aicre-re.com/contact-us" target="_blank"
              >Get Started</v-btn
            >
          </v-col>
        </v-row>
      </div>
      --->
    </div>
  </div>
</template>

<script>
import numeral from 'numeral';
import mapboxgl from 'mapbox-gl';
import { mapGetters } from 'vuex';
import StrDetail from '@/components/StrDetail';
import NeighborhoodsService from '@/services/neighborhoods.service';
import StrsService from '@/services/strs.service';

export default {
  name: 'WidgetExplorerCluster',
  components: { StrDetail },
  data() {
    return {
      map: null,
      drawer: false,
      numeral,
      loadingPropertiesGeo: false,
      loadingPropertiesList: false,
      mapSearchTimer: '',
      activeParcel: null,
      propertiesGeo: [],
      propertiesList: [],
      propertiesFilters: [],
      propertiesFilterCount: 0,
      propertiesMapCount: 0,
      propertyDetail: null,
      propertyQuickSearch: [],
      propertiesCluster: [],
      propertiesLayers: { zone_overlays: { features: [] }, streets: { features: [] } },
      searchPropertiesText: '',
      textSearchTimer: '',
      parcelDialog: false,
      showContactInfo: false,
      contactList: [],
      mapType: 'dark',
      mapTile: 'light-v10',
      search: '',
      tableKey: '1',
      searchPropertyListText: null,
      pageNumProperties: 1,
      pagingPropertiesPerPage: 4,
      maxZoom: 13,
      neighborhoods: [],
      neighborhoodId: '',
      neighborhoodGeos: [],
      loadingNeighborhoodCluster: false,
      markersOnScreen: {},
      markers: {},
      colors: ['#e55e5e', '#fbb03b', '#3bb2d0'],
      selectedRateLegend: {
        abbr: 'anr',
        title: 'Average Nightly Rate',
        index: 'publishNightlyRate_index',
        value: 'publishNightlyRate',
      },
      rateChoices: [
        {
          abbr: 'anr',
          title: 'Average Nightly Rate',
          index: 'publishNightlyRate_index',
          value: 'publishNightlyRate',
        },
        {
          abbr: 'ocr',
          title: 'Occupancy Rate',
          index: 'occupancyRate_index',
          value: 'occupancyRateRate',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      activeGeoId: 'getActiveGeoId',
    }),
  },
  watch: {
    activeGeoId() {
      this.loadMap();
    },
  },
  mounted() {
    this.selectedRateLegend = this.rateChoices.filter((item) => {
      return item.abbr === this.$route.query.type;
    })[0];
    this.getNeighborhoods();
    if (this.$store.state.activeGeoId) {
      this.loadMap();
    }
  },
  methods: {
    // Load initial map
    loadMap() {
      var vm = this;
      mapboxgl.accessToken =
        'pk.eyJ1IjoiY2hhcG1hc3dlbXBvd2VyIiwiYSI6ImNraDZ0Z2M5eDAyY2kyeGsxYno1enU1MGgifQ.P6K0qDTB7-ccH0OaD4K2hg';
      // Init map settings
      this.map = new mapboxgl.Map({
        container: 'mapdiv',
        style: 'mapbox://styles/mapbox/' + this.mapTile + '',
        center: this.$store.state.activeGeoLongLat,
        zoom: 13,
      });

      // no scroll zoom
      this.map.scrollZoom.disable();
      // Add map controls
      this.map.addControl(new mapboxgl.NavigationControl());
      // On map load event
      this.map.on('load', function () {
        // init map parcel data
        vm.searchProperties();
      });
    },
    // Get all neighborhoods
    async getNeighborhoods() {
      NeighborhoodsService.getNeighborhoods()
        .then((response) => {
          this.neighborhoods = response.data;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => (this.errored = false));
    },
    // Select the neighborhood
    selectNeighborhood() {
      let neighborhood = this.neighborhoods.filter((item) => {
        return parseInt(item.id) === parseInt(this.neighborhoodId);
      });

      var poly = [];
      for (var i = 0; i < neighborhood[0].geopolygon[0].length; i++) {
        poly.push([neighborhood[0].geopolygon[0][i].x, neighborhood[0].geopolygon[0][i].y]);
      }

      var districtOverlayMapLayer = this.map.getLayer('district-fill');
      if (typeof districtOverlayMapLayer !== 'undefined') {
        this.map.removeLayer('district-fill');
        this.map.removeLayer('district-outline');
        this.map.removeSource('district-data');
      }

      this.map.addSource('district-data', {
        type: 'geojson',
        data: {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: [poly],
          },
        },
      });
      // Add a new layer to visualize the polygon.
      this.map.addLayer({
        id: 'district-fill',
        type: 'fill',
        source: 'district-data', // reference the data source
        layout: {},
        paint: {
          'fill-color': '#223b53', // blue color fill
          'fill-opacity': 0.1,
        },
      });
      this.map.addLayer({
        id: 'district-outline',
        type: 'line',
        source: 'district-data',
        layout: {},
        paint: {
          'line-color': '#223b53',
          'line-width': 1,
        },
      });

      var longlat = [parseFloat(neighborhood[0].lng), parseFloat(neighborhood[0].lat)];
      this.$store.commit('setNeighborhood', neighborhood[0]);
      this.map.flyTo({ center: longlat, zoom: 13 });
    },
    refreshData() {
      this.searchProperties();
      // may not want to call this twice
      //this.getClusterNeighborhoods();
    },
    // main search properties method
    searchProperties() {
      // exit if zoomed out and only show clusters
      // turn back on if not showing dots
      if (this.map.getZoom() <= this.maxZoom) {
        // return;
      }

      // only get map geo for map view
      this.updateMapParcels();
      this.loadingPropertiesList = true;
      StrsService.getStrs()
        .then((response) => {
          this.propertiesList = response.data;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => {
          this.loadingPropertiesList = false;
          this.tableKey = '12';
        });
    },
    // Currency formatting
    formatCurrency(value) {
      return Number(parseFloat(value)).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    // Decimal formatting
    formatDecimal(value) {
      return Number(parseFloat(value)).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    // Changing maps
    changeMaps() {
      if (this.mapType === 'street') {
        this.mapTile = 'streets-v11';
      } else if (this.mapType === 'light') {
        this.mapTile = 'light-v10';
      } else if (this.mapType == 'dark') {
        this.mapTile = 'dark-v10';
      } else if (this.mapType == 'street-view') {
        this.mapTile = 'dark-v10';
      }
      this.loadMap();
    },
    // update map with new data as the user moves the map
    updateMapParcels() {
      var vm = this;
      vm.loadingPropertiesGeo = true;
      vm.propertiesGeo = [];

      // Get str geo placements
      StrsService.getStrsGeo()
        .then((response) => {
          vm.propertiesGeo = response.data;
          vm.propertiesMapCount = vm.propertiesGeo.length;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
          this.loadingPropertiesGeo = false;
        })
        .finally(() => {
          vm.map.addSource('earthquakes', {
            type: 'geojson',
            data: vm.propertiesGeo,
          });
          vm.map.addLayer({
            id: 'population',
            type: 'circle',
            source: 'earthquakes',
            paint: {
              // Make circles larger as the user zooms from z12 to z22.
              'circle-radius': {
                base: 1.5,
                stops: [
                  [12, 2],
                  [22, 180],
                ],
              },
              // Color circles by ethnicity, using a `match` expression.
              'circle-color': [
                'match',
                ['get', vm.selectedRateLegend.index],
                1,
                '#e55e5e',
                2,
                '#fbb03b',
                3,
                '#3bb2d0',
                4,
                '#3bb2d0',
                '#ccc',
              ],
            },
          });
          this.loadingPropertiesGeo = false;
        });
    },
  },
};
</script>
<style>
.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-attrib-inner {
  display: none !important;
}
</style>
<style scoped>
.text-sm-body-custom {
  font-size: 0.775rem;
  line-height: 14px;
}
.cus-legend {
  position: absolute;
  top: 115px;
  right: 80px;
  z-index: 1;
}
.cus-legend-title {
  font-size: 0.9em;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
}
.cus-legend-colorbar {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  overflow: hidden;
}
.cus-legend-text {
  color: white;
  font-weight: bold;
  font-size: 0.85em;
  padding: 5px;
}
.cus-legend-green,
.cus-legend-yellow,
.cus-legend-red {
  margin: 5px;
  width: 20px;
  height: 20px;
  background: #3bb2d0;
  float: left;
  border: 1px solid #eaeaea;
}
.cus-legend-yellow {
  background: #fbb03b;
}
.cus-legend-red {
  background: #e55e5e;
}
</style>
