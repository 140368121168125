import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {},
  state: {
    loading: false,
    toast: false,
    toastMessage: '',
    activeGeoId: '',
    activeGeoLongLat: [],
    activeGeoParcelLink: '',
    activeGeoAssessorLink: '',
    activeGeoConfig: '',
    propertyFilters: false,
    propertyFilterConfig: {
      zipcode: [],
      zone_overlay: [],
      total_value: [0, 10000000],
      sale_price: [0, 10000000],
      market_price: [0, 10000000],
      zone: [],
      acre_value: [0, 1000],
      use_desc: [],
      use_code: [],
      lbcs_function: [],
      violations: [],
      sold_year: 2021,
      is_streetside: 0,
    },
    propertyDetail: false,
    propertyDetailData: {},
    strDetail: false,
    strDetailData: {},
    strCompsDetail: false,
    strCompSearchData: {},
    mapBoundary: [],
    activeNeighborhoodId: '',
    activeNeighborhoodName: '',
    activeNeighborhoodLong: '',
    activeNeighborhoodLat: '',
  },
  mutations: {
    showLoading(state, payload) {
      state.loading = payload;
    },
    showToast(state, payload) {
      state.toast = payload.active;
      state.toastMessage = payload.message;
    },
    setGeo(state, payload) {
      state.activeGeoId = payload.id;
      state.activeGeoLongLat = payload.longlat;
      state.activeGeoParcelLink = payload.parcellink;
      state.activeGeoAssessorLink = payload.assessorlink;
      state.activeGeoConfig = payload.config;
      // set here incase default
      state.propertyFilterConfig.geo_id = payload.id;
    },
    showPropertyFilters(state, payload) {
      state.propertyFilters = payload;
    },
    setPropertyFilters(state, payload) {
      state.propertyFilterConfig = payload;
    },
    showPropertyDetail(state, payload) {
      state.propertyDetail = payload;
    },
    setPropertyDetail(state, payload) {
      state.propertyDetailData = payload;
    },
    setMapBoundary(state, payload) {
      state.mapBoundary = payload;
      // set here when not refreshed by the filter menu
      state.propertyFilterConfig.boundary = payload;
    },
    setNeighborhood(state, payload) {
      state.activeNeighborhoodId = payload.id;
      state.activeNeighborhoodName = payload.name;
      state.activeNeighborhoodLng = payload.lng;
      state.activeNeighborhoodLat = payload.lat;
    },
    showStrDetail(state, payload) {
      state.strDetail = payload;
    },
    showStrCompsDetail(state, payload) {
      state.strCompsDetail = payload;
    },
    setStrDetail(state, payload) {
      state.strDetailData = payload;
    },
    setStrCompSearchData(state, payload) {
      state.strCompSearchData = payload;
    },
  },
  getters: {
    getActiveGeoId(state) {
      return state.activeGeoId;
    },
    getActiveParcel(state) {
      return state.propertyDetailData;
    },
    getActiveStr(state) {
      return state.strDetailData;
    },
    getActiveCompSearchData(state) {
      return state.strCompSearchData;
    },
  },
});
