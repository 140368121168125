import Vue from 'vue';
import App from '@/App.vue';
import axios from 'axios';
import store from '@/store';
import VueAxios from 'vue-axios';
import vuetify from '@/plugins/vuetify';
import './vee-validate';
import router from '@/router.js';
import JsonCSV from 'vue-json-csv';

Vue.component('downloadCsv', JsonCSV);
Vue.config.productionTip = false;
Vue.prototype.$http = axios;

Vue.use(VueAxios, axios);

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
