import { required, email, regex, min, max } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

extend('required', {
  ...required,
  message: 'This field is required',
});

extend('max', {
  ...max,
  message: 'This field must be {length} characters or less',
});

extend('min', {
  ...min,
  message: 'This field must be a minimum of {length} characters',
});

extend('email', {
  ...email,
  message: 'This field must be a valid email',
});

extend('password', {
  ...regex,
  message: 'This field must contain at least one lower case letter, upper case letter and number.',
});
