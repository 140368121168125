import axios from 'axios';
import { PRODUCTION_WIDGET_API_URL } from '@/settings';

const API_URL = PRODUCTION_WIDGET_API_URL + 'neighborhood';

class NeighborhoodsService {
  getNeighborhoods() {
    return axios.get(API_URL).then((response) => {
      return response.data;
    });
  }
  getNeighborhoodsGeo() {
    return axios.get(API_URL + '/geo').then((response) => {
      return response.data;
    });
  }
  getNeighborhoodsStats() {
    return axios.get(API_URL + '/stats').then((response) => {
      return response.data;
    });
  }
}
export default new NeighborhoodsService();
