import axios from 'axios';
import { PRODUCTION_WIDGET_API_URL } from '@/settings';

const API_URL = PRODUCTION_WIDGET_API_URL + 'str';

class StrsService {
  getStrs() {
    return axios.get(API_URL + '/').then((response) => {
      return response.data;
    });
  }
  getStrsGeo() {
    return axios.get(API_URL + '/geo').then((response) => {
      return response.data;
    });
  }
  postStrsBounding(boundingBox) {
    return axios.post(API_URL + '/bounding', boundingBox).then((response) => {
      return response.data;
    });
  }
  getStrsList() {
    return axios.get(API_URL + '/list').then((response) => {
      return response.data;
    });
  }
  getCompByParcel(postParams) {
    return axios.post(API_URL + '/comp', postParams).then((response) => {
      return response.data;
    });
  }
  getStdDevByLongLat(postParams) {
    return axios.post(API_URL + '/stddev/longlat', postParams).then((response) => {
      return response.data;
    });
  }
  getCompByParcelGeo(postParams) {
    return axios.post(API_URL + '/comp/geo', postParams).then((response) => {
      return response.data;
    });
  }
  getCompByLongLatGeo(postParams) {
    return axios.post(API_URL + '/comp/longlat/geo', postParams).then((response) => {
      return response.data;
    });
  }
  getCompSummaryByParcel(postParams) {
    return axios.post(API_URL + '/comp/summary', postParams).then((response) => {
      return response.data;
    });
  }
  getCompSummaryByLongLat(postParams) {
    return axios.post(API_URL + '/comp/longlat/summary', postParams).then((response) => {
      return response.data;
    });
  }
  getPermitsCompsByParcel(postParams) {
    return axios.post(API_URL + '/permits/comp', postParams).then((response) => {
      return response.data;
    });
  }
  getPermitSummaryByParcel(postParams) {
    return axios.post(API_URL + '/permits/zones/summary', postParams).then((response) => {
      return response.data;
    });
  }
  getPermitSummaryByLongLat(postParams) {
    return axios.post(API_URL + '/permits/zones/longlat/summary', postParams).then((response) => {
      return response.data;
    });
  }
}

export default new StrsService();
