<template>
  <!---
  // Str details dialog
  ---->
  <v-dialog v-model="$store.state.strCompsDetail" class="v-dia" fullscreen>
    <v-card>
      <v-overlay :value="loadingCompListGeo" z-index="998">
        <v-progress-circular size="64" indeterminate></v-progress-circular>
      </v-overlay>
      <div class="pl-2 pr-2">
        <div ref="topContainer">
          <v-row class="pa-0 ma-0">
            <v-col class="backSearch pl-1 pt-1">
              <v-btn outlined size="x-small" color="primary" @click="hidePropertyDetail()">Back to search</v-btn>
            </v-col>
            <v-col class="text-right pr-1 pt-1">
              <v-btn outlined size="x-small" color="accent" @click="hidePropertyDetail()">Close</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-if="compListGeo.features.length && !loadingCompList"
              style="padding-left: 18px !important"
              class="pa-0"
            >
              <div style="min-width: 160px">
                <v-list-item-content class="pa-0">
                  <div class="pa-0 accent--text" style="padding-right: 0!; min-height: 0px">
                    <strong>Bedrooms:</strong> {{ this.$store.state.strCompSearchData.bedrooms }}
                  </div>
                  <div class="pa-0 accent--text" style="padding-right: 0!; min-height: 0px">
                    <strong>Bathrooms:</strong> >= {{ this.$store.state.strCompSearchData.bathrooms }}
                  </div>
                  <div class="pa-0 accent--text" style="padding-right: 0!; min-height: 0px">
                    <strong>Within:</strong> {{ this.$store.state.strCompSearchData.distance }} miles
                  </div>
                  <div class="pa-0" style="padding-right: 0!; min-height: 0px">
                    <strong>NOO Permits:</strong> {{ permitSummaryTotalNOO }}
                  </div>
                  <div class="pa-0" style="padding-right: 0!; min-height: 0px">
                    <strong>OO Permits:</strong> {{ permitSummaryTotalOO }}
                  </div>
                </v-list-item-content>
              </div>
            </v-col>
            <v-col v-if="compListGeo.features.length && !loadingCompList" class="pa-0" style="min-width: 160px">
              <div class="pa-2">
                <v-list-item v-for="(field, i) in permitSummary" :key="i" class="pa-0" style="min-height: 0px">
                  <v-list-item-content class="pa-0">
                    <v-list-item class="pa-0" style="padding-left: 0px!; padding-right: 0!; min-height: 0px">
                      <strong> {{ field.permit_type }}:</strong>
                      {{ formatDecimal((field.cnt / permitSummaryTotal) * 100) }}% -
                      {{ field.zoning }}
                    </v-list-item>
                  </v-list-item-content>
                </v-list-item>
                <div style="min-width: 180px; padding-left: 30px">Zoning Types</div>
              </div>
            </v-col>
            <!---
            <v-col>
              <v-card>
                <v-card-text class="pb-0 font-weight-medium"> APN: {{ propertyDetails.parcelnumb }} </v-card-text>
                <v-card-text class="pt-0 pb-0">
                  {{ propertyDetails.address }}
                </v-card-text>
                <v-card-text class="pt-0">
                  {{ propertyDetails.usedesc }}
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    class="justify-center"
                    :href="parcelViewLink"
                    elevation="2"
                    x-small
                    target="_window"
                    color="secondary"
                  >
                    Parcel Viewer
                  </v-btn>
                  <v-btn
                    class="justify-center"
                    :href="assessorViewLink"
                    elevation="2"
                    x-small
                    target="_window"
                    color="secondary"
                  >
                    Assessor Office</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
            --->
            <v-col v-if="compListGeo.features.length && !loadingCompList" class="pa-0">
              <div style="min-width: 180px; text-align: center" class="pa-2">
                <div class="headline text--accent">
                  {{ formatCurrency(stdDev[0].annualRevenue_avg * this.$store.state.strCompSearchData.bedrooms) }}
                </div>
                <div class="caption text--secondary">
                  {{
                    calcStdRange(
                      stdDev[0].annualRevenue_avg * this.$store.state.strCompSearchData.bedrooms,
                      stdDev[0].annualRevenue_std * this.$store.state.strCompSearchData.bedrooms,
                      stdDev[0].stddev_count,
                      'currency',
                    )
                  }}
                </div>
                <div>Avg Annual Revenue</div>
              </div>
            </v-col>
            <v-col v-if="compListGeo.features.length && !loadingCompList" class="pa-0">
              <div style="min-width: 130px; text-align: center" class="pa-2">
                <div class="headline text--accent">{{ formatDecimal(stdDev[0].occupancyRate_avg * 100) }}%</div>
                <div class="caption text--secondary">
                  {{
                    calcStdRange(
                      stdDev[0].occupancyRate_avg,
                      stdDev[0].occupancyRate_std,
                      stdDev[0].stddev_count,
                      'percent',
                    )
                  }}%
                </div>
                <div>Avg Occupancy</div>
              </div>
            </v-col>
            <v-col v-if="compListGeo.features.length && !loadingCompList" class="pa-0">
              <div style="min-width: 130px; text-align: center" class="pa-2">
                <div class="headline text--accent">
                  {{ formatCurrency(stdDev[0].publishNightlyRate_avg * this.$store.state.strCompSearchData.bedrooms) }}
                </div>
                <div class="caption text--secondary">
                  {{
                    calcStdRange(
                      stdDev[0].publishNightlyRate_avg * this.$store.state.strCompSearchData.bedrooms,
                      stdDev[0].publishNightlyRate_std * this.$store.state.strCompSearchData.bedrooms,
                      stdDev[0].stddev_count,
                      'currency',
                    )
                  }}
                </div>
                <div>Avg Nightly Rate</div>
              </div>
            </v-col>
            <!---
            <v-col v-if="compListGeo.features.length && !loadingCompList" class="pa-0">
              <div style="min-width: 140px" class="pa-2">
                <span class="headline text--primary">{{ formatDecimal(stdDev[0].bookedDays_avg) }}</span>
                <div>Avg Days Booked</div>
              </div>
            </v-col>
            <v-col v-if="compListGeo.features.length && !loadingCompList" class="pa-0">
              <div class="pa-2">
                <v-list-item class="pa-0" style="min-height: 0px">
                  <v-list-item-content class="pa-0">
                    <v-list-item
                      class="pa-0"
                      style="min-width: 240px; padding-left: 0px!; padding-right: 0!; min-height: 0px"
                    >
                      <strong> Nightly Rate Std dev:</strong>
                      {{
                        formatCurrency(stdDev[0].publishNightlyRate_std * this.$store.state.strCompSearchData.bedrooms)
                      }}
                    </v-list-item>
                    <v-list-item class="pa-0" style="padding-left: 0px!; padding-right: 0!; min-height: 0px">
                      <strong> Annual Revenue Std dev:</strong>
                      {{ formatCurrency(stdDev[0].annualRevenue_std * this.$store.state.strCompSearchData.bedrooms) }}
                    </v-list-item>
                    <v-list-item class="pa-0" style="padding-left: 0px!; padding-right: 0!; min-height: 0px">
                      <strong> Occupancy Rate Std dev:</strong>
                      {{ formatDecimal(stdDev[0].occupancyRate_std * 100) }}%
                    </v-list-item>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </v-col>
            --->
          </v-row>
          <v-row v-if="!loadingCompList" class="justify-center">
            <v-alert
              v-if="compListGeo.features.length - compListTotal < 4 && compListGeo.features.length > 1"
              type="warning"
              >There are only <strong>{{ compListGeo.features.length }} comps </strong> try adjusting the options to get
              more comps
            </v-alert>
            <v-alert v-if="compListGeo.features.length == 1" type="warning">
              There is only <strong>{{ compListGeo.features.length }} comp </strong> try adjusting the options to get
              more comps
            </v-alert>
            <v-alert v-if="compListGeo.features.length == 0" type="error"
              >There are <strong>{{ compListGeo.features.length }} comps </strong> try adjusting the options to get more
              comps
            </v-alert>
            <v-alert v-if="compListGeo.features.length >= 4" class="pa-1 pr-2" style="float: right" type="warning"
              ><strong>{{ compListTotal }}</strong> of {{ compListGeo.features.length }}
              listings outside the normal ranges
            </v-alert>
          </v-row>
        </div>
        <v-row no-gutters style="margin-right: 0; margin-left: 0" fluid class="fill-height">
          <v-col cols="12" lg="6" md="6" xs="12" sm="12">
            <div id="mapdivdetails"></div>
          </v-col>
          <v-col ref="scrollCol" cols="12" lg="6" md="6" xs="12" sm="12">
            <v-card
              v-for="item in compListGeo.features"
              :key="item.properties.propId"
              :ref="item.properties.propId.replace('-', '')"
              style="float: left; height: 480px; margin: 8px"
              class="mt-2 comp-card"
              elevation="2"
            >
              <div style="position: absolute; top: 0; right: 0; z-index: 9">
                <v-chip v-if="!item.properties.publishNightlyRate_good" class="ma-2" color="orange" text-color="white"
                  >! Nightly Rate</v-chip
                >
                <v-chip v-if="!item.properties.annualRevenue_good" class="ma-2" color="orange" text-color="white"
                  >! Annual Revenue</v-chip
                >
                <v-chip v-if="!item.properties.occupancyRate_good" class="ma-2" color="orange" text-color="white"
                  >! Occupancy Rate</v-chip
                >
                <v-chip
                  v-if="
                    item.properties.occupancyRate_good &&
                    item.properties.annualRevenue_good &&
                    item.properties.publishNightlyRate_good
                  "
                  class="ma-2"
                  color="green"
                  text-color="white"
                  >&#x2713;</v-chip
                >
              </div>
              <v-carousel height="200" :hide-delimiters="true" :max="5">
                <v-carousel-item
                  v-for="(itemImg, i) in item.properties.allImageUrls.split(',')"
                  :key="i"
                  :src="itemImg"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                ></v-carousel-item>
              </v-carousel>
              <v-card-text>
                <div class="subtitle-1">
                  {{ item.properties.maxGuests }} guests &middot; {{ item.properties.bedrooms }} bedrooms &middot;
                  {{ item.properties.bathrooms }} baths
                </div>
                <div
                  :style="{ visibility: item.properties.annualRevenue > 0 ? 'visible' : 'hidden' }"
                  class="mt-2 text-subtitle-1"
                >
                  <span class="title font-weight-medium accent--text">
                    {{ formatCurrency(item.properties.annualRevenue) }}
                  </span>
                  <span> / year</span>
                </div>
                <div
                  :style="{ visibility: item.properties.publishNightlyRate > 0 ? 'visible' : 'hidden' }"
                  class="mt-2 text-subtitle-1"
                >
                  <span class="title font-weight-medium accent--text">
                    {{ formatCurrency(item.properties.publishNightlyRate) }}
                  </span>
                  <span> night</span>
                  <span
                    :style="{ visibility: item.properties.occupancyRate > 0 ? 'visible' : 'hidden' }"
                    class="ml-5 title font-weight-medium accent--text"
                  >
                    {{ formatDecimal(item.properties.occupancyRate * 100) }}%
                  </span>
                  <span :style="{ visibility: item.properties.occupancyRate > 0 ? 'visible' : 'hidden' }"> booked</span>
                </div>
                <div class="title-1 mt-5" style="min-height: 40px; max-height: 60px">
                  {{ item.properties.listingTitle }}
                </div>
              </v-card-text>
              <v-card-actions class="pt-0">
                <span>
                  <v-btn :href="item.properties.bookingUrl" target="_window" color="secondary"> View listing </v-btn>
                </span>
                <span class="pl-3"> {{ formatDecimalLong(item.properties.distance * 0.000621371) }} mi </span>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';
import StrService from '@/services/strs.service';
//import PropertiesService from '@/services/properties.service';
import mapboxgl from 'mapbox-gl';

export default {
  name: 'StrCompsDetail',
  components: {},
  data() {
    return {
      numeral,
      compList: [],
      compListGeo: { features: [] },
      compSummary: {},
      permitSummary: {},
      propertyDetails: {},
      loadingCompList: false,
      loadingCompListGeo: false,
      mapType: 'dark',
      mapTile: 'light-v10',
      showMap: false,
      zoomsetting: 16,
    };
  },
  computed: {
    ...mapGetters({
      strCompSearchData: 'getActiveCompSearchData',
      activeGeoId: 'getActiveGeoId',
    }),
    colHeight() {
      return window.innerHeight - 12 - this.$refs.topContainer.clientHeight;
    },
    permitSummaryTotal() {
      var sum = 0;
      this.permitSummary.forEach((entry) => {
        sum += entry.cnt;
      });
      return sum;
    },
    permitSummaryTotalNOO() {
      var sum = 0;
      this.permitSummary.forEach((entry) => {
        console.log(entry);
        if (entry.permit_type == 'NOO') {
          sum += entry.cnt;
        }
      });
      return sum;
    },
    permitSummaryTotalOO() {
      var sum = 0;
      this.permitSummary.forEach((entry) => {
        if (entry.permit_type == 'OO') {
          sum += entry.cnt;
        }
      });
      return sum;
    },
    parcelViewLink() {
      return this.$store.state.activeGeoParcelLink.replace(
        '{parcelnum}',
        this.$store.state.strCompSearchData.parcelnumb,
      );
    },
    assessorViewLink() {
      return this.$store.state.activeGeoAssessorLink.replace(
        '{parcelnum}',
        this.$store.state.strCompSearchData.parcelnumb,
      );
    },
    compListTotal() {
      var badRecords = 0;
      this.compListGeo.features.forEach((entry) => {
        if (
          entry.properties.occupancyRate_good &&
          entry.properties.publishNightlyRate_good &&
          entry.properties.annualRevenue_good
          // eslint-disable-next-line no-empty
        ) {
        } else {
          badRecords += 1;
        }
      });
      return badRecords;
    },
    isMobile: function () {
      var check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a,
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
            a.substr(0, 4),
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
  },
  watch: {
    strCompSearchData() {
      this.getComps();
      this.showMap = false;
      this.loadingCompListGeo = true;
    },
  },
  methods: {
    calcStdRange($value, $stdDev, $deviation, $type) {
      var returnVal = '';
      if ($type == 'currency') {
        returnVal =
          this.formatCurrency($value - $stdDev * $deviation) +
          ' - ' +
          this.formatCurrency($value + $stdDev * $deviation);
      } else {
        returnVal =
          this.formatDecimal(100 * ($value - $stdDev * $deviation)) +
          '%  - ' +
          this.formatDecimal(100 * ($value + $stdDev * $deviation));
      }
      return returnVal;
    },
    showMapAction() {
      this.showMap = true;
    },
    hidePropertyDetail() {
      this.$store.commit('showStrCompsDetail', false);
      this.showMap = false;
    },
    filterParcels(index) {
      if (index > 0) {
        var tempholdOld = this.compList[0];
        var tempholdNew = this.compList[index];
        this.compList.splice(0, 1);
        this.compList.splice(index - 1, 1);
        this.compList.splice(0, 0, tempholdNew);
        this.compList.splice(index, 0, tempholdOld);
      }
      //var strindef = this.compList[0].propId.replace('-', '');
      //var def = `this.$refs.${strindef}`;
      //console.log(eval(def));
    },
    getComps() {
      /*
      PropertiesService.getPropertyDetailsByParcelNum({
        pageId: 1,
        geo_id: this.activeGeoId,
        parcel_num: this.$store.state.strCompSearchData.parcelnumb,
        limit: 1,
      })
        .then((response) => {
          this.propertyDetails = response.data[0];
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => {});
      */
      StrService.getCompSummaryByLongLat({
        pageId: 1,
        geo_id: this.activeGeoId,
        longlat: this.$store.state.strCompSearchData.longlat,
        bedrooms: this.$store.state.strCompSearchData.bedrooms,
        bathrooms: this.$store.state.strCompSearchData.bathrooms,
        distance: this.$store.state.strCompSearchData.distance,
        limit: 30,
      })
        .then((response) => {
          this.compSummary = response.data[0];
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => {});

      StrService.getStdDevByLongLat({
        pageId: 1,
        geo_id: this.activeGeoId,
        longlat: this.$store.state.strCompSearchData.longlat,
        bedrooms: this.$store.state.strCompSearchData.bedrooms,
        bathrooms: this.$store.state.strCompSearchData.bathrooms,
        distance: this.$store.state.strCompSearchData.distance,
        limit: 30,
      })
        .then((response) => {
          this.stdDev = response.data;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => {
          this.tableKey = '1212';
        });

      StrService.getCompByLongLatGeo({
        pageId: 1,
        geo_id: this.activeGeoId,
        longlat: this.$store.state.strCompSearchData.longlat,
        bedrooms: this.$store.state.strCompSearchData.bedrooms,
        bathrooms: this.$store.state.strCompSearchData.bathrooms,
        distance: this.$store.state.strCompSearchData.distance,
        limit: 30,
      })
        .then((response) => {
          this.compListGeo = response.data;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
          this.loadingCompListGeo = false;
          this.loadingCompList - false;
        })
        .finally(() => {
          this.loadingCompListGeo = false;
          this.loadingCompList - false;
          this.tableKey = '1122';
          this.loadMap();
        });
      StrService.getPermitSummaryByLongLat({
        pageId: 1,
        geo_id: this.activeGeoId,
        longlat: this.$store.state.strCompSearchData.longlat,
        distance: this.$store.state.strCompSearchData.distance,
        limit: 5,
      })
        .then((response) => {
          this.permitSummary = response.data;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => {});
    },
    loadMap() {
      this.loadingCompListGeo = false;
      this.loadingCompList - false;

      // prep scrollable list -should probably move
      if (this.$refs.scrollCol) {
        this.$refs.scrollCol.scrollTop = 0;
      }
      if (!this.isMobile) {
        this.$refs.scrollCol.setAttribute('style', `height:${this.colHeight}px; overflow-y: auto; overflow-x: hidden;`);
      }
      var vm = this;
      mapboxgl.accessToken =
        'pk.eyJ1IjoiY2hhcG1hc3dlbXBvd2VyIiwiYSI6ImNraDZ0Z2M5eDAyY2kyeGsxYno1enU1MGgifQ.P6K0qDTB7-ccH0OaD4K2hg';
      // Init map settings
      this.mapDetails = new mapboxgl.Map({
        container: 'mapdivdetails',
        style: 'mapbox://styles/mapbox/' + this.mapTile + '',
        center: [vm.$store.state.strCompSearchData.longlat.lng, vm.$store.state.strCompSearchData.longlat.lat],
        zoom: 13,
        maxZoom: 20,
        minZoom: 11,
      });

      if (this.$store.state.strCompSearchData.distance > 2) {
        this.mapDetails.setZoom(10);
      }

      // no scroll zoom
      this.mapDetails.scrollZoom.disable();
      // Add map controls
      this.mapDetails.addControl(new mapboxgl.NavigationControl());

      this.mapDetails.on('load', function () {
        var counter = 0;
        for (const markerData of vm.compListGeo.features) {
          const el = document.createElement('div');
          var bgColor = '';
          if (markerData.properties.publishNightlyRate_index == '1') {
            bgColor = '#192a3e';
          } else if (markerData.properties.publishNightlyRate_index == '2') {
            bgColor = '#192a3e';
          } else {
            bgColor = '#192a3e';
          }
          el.innerHTML = `<div class="small-marker-circle-outer" id="${counter}">
            <div class="small-marker-circle-inner" style="background: ${bgColor}"></div>
            <div class="small-marker-circle-label">${vm.formatDecimalLong(
              markerData.properties.distance * 0.000621371,
            )} mi
            </div>
            </div>`;
          new mapboxgl.Marker(el).setLngLat(markerData.geometry.coordinates).addTo(vm.mapDetails);
          el.addEventListener('click', (events) => {
            vm.filterParcels(events.srcElement.parentElement.id);
          });
          counter++;
        }
        new mapboxgl.Marker({ color: '#e31e26' })
          .setLngLat([vm.$store.state.strCompSearchData.longlat.lng, vm.$store.state.strCompSearchData.longlat.lat])
          .addTo(vm.mapDetails);
      });
    },
    // Currency formatting
    formatCurrency(value) {
      return Number(parseFloat(value)).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    // Currency formatting
    formatDecimal(value) {
      return Number(parseFloat(value)).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    // Currency formatting
    formatDecimalLong(value) {
      return Number(parseFloat(value)).toLocaleString('en-US', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
};
</script>
<style>
.v-dialog--fullscreen {
  overflow-x: hidden !important;
}
#mapdivdetails {
  height: 500px;
}
.small-marker-circle-inner {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}
.small-marker-circle-label {
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
  border: 1px solid #000;
  margin-top: -5px;
  font-size: 10px;
}
@media only screen and (max-width: 600px) {
  #mapdivdetails {
    display: none;
  }
  .backSearch {
    display: none;
  }
}
@media screen and (min-width: 1024px) {
  .comp-card {
    width: 45%;
  }
}
@media screen and (max-width: 1024px) {
  .comp-card {
    width: 100%;
  }
}
</style>
