<template>
  <!---
  // Str details dialog
  ---->
  <v-dialog v-model="$store.state.strDetail" width="80%">
    <v-card>
      <v-row v-if="$store.state.strDetailData.allImageUrls">
        <v-col>
          <v-carousel height="380" hide-delimiters>
            <v-carousel-item v-for="item in $store.state.strDetailData.allImageUrls.split(',')" :key="item" eager>
              <v-img :src="item" height="100%" eager />
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
      <div class="pa-5 pt-0">
        <v-row>
          <v-col>
            <h2>{{ $store.state.strDetailData.bedrooms }} Beds | {{ $store.state.strDetailData.bathrooms }} Bath</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" lg="6" md="6">
            <div class="font-weight-bold"><h3>Contact Information</h3></div>
            <div class="mt-5" style="position: relative">
              <div class="blur-box">someone name shawn</div>
              <div class="blur-box">ssss asdsadasds</div>
              <div class="blur-box">6155553344</div>
              <div class="blur-box">name asdsadsac</div>
              <div class="blur-box">asdsadasds</div>
              <div class="blur-box">aa asdsadasds</div>
              <div class="blur-box">ssss aaaaddasdsss asasas1122</div>
              <div class="blur-box">6155553344</div>
              <div class="mt-3">
                <v-btn
                  depressed
                  x-large
                  color="primary"
                  style="padding-right: 60px; padding-left: 60px; position: absolute; top: 50px"
                >
                  <h3>Contact Agent</h3>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="font-weight-bold"><h3>Rental Data</h3></div>
            <div class="pt-5">Estimated Rental Fee</div>
            <div class="font-weight-bold">
              <h2>{{ formatCurrency($store.state.strDetailData.publishNightlyRate) }} /night</h2>
            </div>
            <div class="pt-5">Estimated Occupancy</div>
            <div class="font-weight-bold">
              <h2>{{ formatDecimal($store.state.strDetailData.occupancyRate * 100) }}%</h2>
            </div>
            <div class="pt-5">Annual Revenue Estimate</div>
            <div class="font-weight-bold">
              <h2>{{ formatCurrency($store.state.strDetailData.annualRevenue) }} / year</h2>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="font-weight-bold">Listed On</div>
            <div><img height="60" src="/airbnb_logo.jpg" /></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3>Property Details</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="font-weight-bold">Amentities</div>
            <div v-if="$store.state.strDetailData.amenities">
              {{
                $store.state.strDetailData.amenities
                  .replaceAll('"', '')
                  .replaceAll('_', ' ')
                  .replaceAll('[', '')
                  .replaceAll(']', '')
                  .replaceAll('}', '')
                  .replaceAll('{', '')
              }}
            </div>
          </v-col>
        </v-row>
      </div>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="hidePropertyDetail()"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral';

export default {
  name: 'StrDetail',
  components: {},
  data() {
    return {
      numeral,
    };
  },
  computed: {
    ...mapGetters({
      strDetailData: 'getActiveStr',
    }),
  },
  methods: {
    hidePropertyDetail() {
      this.$store.commit('showStrDetail', false);
    },
    // Currency formatting
    formatCurrency(value) {
      return Number(parseFloat(value)).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    // Currency formatting
    formatDecimal(value) {
      return Number(parseFloat(value)).toLocaleString('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
  },
};
</script>
<style>
.blur-box {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
  width: 100%;
}
</style>
