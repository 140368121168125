<template>
  <div>
    <!--- 
    // Main login window 
    --->
    <v-app>
      <v-main>
        <v-container fluid>
          <router-view />
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  data() {
    return {
      geoId: this.$store.state.activeGeoId,
      geos: [
        {
          name: 'Davidson County, TN',
          id: 47037,
          longlat: [-86.7816, 36.1627],
          parcellink: 'https://maps.nashville.gov/ParcelViewer/?parcelID={parcelnum}',
          assessorlink: 'http://www.padctn.org/prc/#/search/1?searchType=parcel&searchTerm={parcelnum}&searchNumber=',
          config: {
            streetside: true,
            llcOwned: true,
            violations: true,
            usecode: true,
            zone_overlays: false,
            use_desc: true,
            ludesc: false,
            lbcs_function: false,
            OIC: true,
            uspsVacancy: true,
            hideGov: true,
            acreValue: true,
            totalValue: true,
            marketPrice: true,
            salePrice: true,
            soldYear: true,
            builtYear: false,
            proptype: false,
          },
        },
        {
          name: 'Hamilton County, TN',
          id: 47065,
          longlat: [-85.3097, 35.0456],
          parcellink: 'https://ssssss{parcelnum}',
          assessorlink: 'http://ssss{parcelnum}&searchNumber=',
          config: {
            streetside: false,
            llcOwned: true,
            violations: false,
            usecode: true,
            zone_overlays: false,
            use_desc: false,
            ludesc: true,
            lbcs_function: false,
            OIC: false,
            uspsVacancy: true,
            hideGov: false,
            acreValue: true,
            totalValue: true,
            marketPrice: true,
            salePrice: true,
            soldYear: true,
            builtYear: false,
            proptype: false,
          },
        },
        {
          name: 'Montgomery County, TN',
          id: 6901,
          longlat: [-87.3595, 36.5298],
          parcellink: 'https://ssssss{parcelnum}',
          assessorlink: 'http://ssss{parcelnum}&searchNumber=',
          config: {
            streetside: false,
            llcOwned: true,
            violations: false,
            usecode: true,
            zone_overlays: false,
            use_desc: false,
            ludesc: false,
            lbcs_function: false,
            OIC: false,
            uspsVacancy: false,
            hideGov: false,
            acreValue: true,
            totalValue: true,
            marketPrice: false,
            salePrice: true,
            soldYear: true,
            builtYear: true,
            proptype: true,
          },
        },
        {
          name: 'Jefferson County, KY',
          id: 6902,
          longlat: [-85.764771, 38.328732],
          parcellink: 'https://ssssss{parcelnum}',
          assessorlink: 'http://ssss{parcelnum}&searchNumber=',
          config: {
            streetside: false,
            llcOwned: false,
            violations: false,
            usecode: true,
            zone_overlays: false,
            use_desc: false,
            ludesc: false,
            lbcs_function: false,
            OIC: false,
            uspsVacancy: false,
            hideGov: false,
            acreValue: true,
            totalValue: true,
            marketPrice: false,
            salePrice: true,
            soldYear: true,
            builtYear: true,
            proptype: true,
          },
        },
      ],
    };
  },
  computed: {},
  mounted() {
    this.selectGeo();
    this.$vuetify.theme.themes.light.primary = '#192A3E';
    this.$vuetify.theme.themes.light.secondary = '#5b667c';
    this.$vuetify.theme.themes.light.accent = '#E31E26';
  },
  methods: {
    selectGeo() {
      // set to first geo
      this.$store.commit('setGeo', this.geos[0]);
    },
  },
};
</script>
