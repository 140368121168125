<template>
  <div style="margin-top: 0px">
    <div>
      <div style="width: 250px">
        <v-select
          v-model="neighborhoodId"
          width="200px"
          class="mt-6"
          :items="neighborhoods"
          dense
          item-text="name"
          item-value="id"
          outlined
          label="Select a neighborhood"
          @input="selectNeighborhood"
        >
        </v-select>
      </div>
    </div>
    <div style="margin-top: 0px">
      <str-detail />
      <!----
      // Map view
      ------>
      <v-row>
        <v-col class="pt-0">
          <v-row>
            <v-col style="padding-top: 0px">
              <v-progress-linear
                :style="{ visibility: loadingPropertiesGeo | loadingNeighborhoodCluster ? 'visible' : 'hidden' }"
                indeterminate
              ></v-progress-linear>
              <div id="mapdiv" style="width: 100%; height: 600px"></div>
            </v-col>
          </v-row>
          <!---
          // Property side list
          ----->
          <div
            v-if="!drawer"
            style="
              position: absolute;
              right: 10px;
              z-index: 99;
              background: #fff;
              height: 166px;
              top: 280px;
              padding: 6px;
              border-radius: 16px 0 0 16px;
              cursor: pointer;
            "
            @click.stop="drawer = true"
          >
            <div
              style="
                writing-mode: vertical-rl;
                text-orientation: mixed;
                margin-top: 20px;
                font-size: 12px;
                font-weight: bold;
              "
            >
              Property Listing
            </div>
            <div style="margin-top: 10px; text-align: center; font-size: 26px">&#187;</div>
          </div>
          <v-navigation-drawer v-model="drawer" absolute temporary right class="pa-5" width="420">
            <v-row>
              <v-col>LISTED RENTALS</v-col>
            </v-row>
            <v-row>
              <v-col v-if="propertiesList.length > 0" class="pt-0">
                <v-row>
                  <v-progress-linear
                    :style="{ visibility: loadingPropertiesList ? 'visible' : 'hidden' }"
                    indeterminate
                  ></v-progress-linear>
                  <div v-for="(item, index) in paginateProperties" :key="index">
                    <v-card class="pb-2 mx-auto" style="box-shadow: none" @click="getParcel(item.propId)">
                      <v-row no-gutters class="mt-2">
                        <v-col cols="5">
                          <v-img
                            :src="item.allImageUrls.split(',')[0]"
                            :lazy-src="item.allImageUrls.split(',')[0]"
                            width="255px"
                          >
                            <template v-slot:placeholder>
                              <v-row class="fill-height ma-0" align="center" justify="center">
                                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                              </v-row>
                            </template>
                            <span
                              style="
                                font-weight: bold;
                                bottom: 6px;
                                position: absolute;
                                left: 18px;
                                background: #fff;
                                border-radius: 4px;
                                padding: 2px;
                                font-size: 12px;
                              "
                            >
                              {{ item.annualRevenue }}
                            </span>
                            <span
                              style="
                                position: absolute;
                                bottom: 6px;
                                font-size: 10px;
                                color: #fff;
                                right: 6px;
                                font-weight: bold;
                                text-transform: uppercase;
                              "
                            >
                              Occupancy Rate</span
                            >
                          </v-img>
                        </v-col>
                        <v-col>
                          <v-card-subtitle
                            class="pa-0 pl-3 font-weight-bold"
                            style="word-break: break-word; line-height: 16px"
                          >
                            {{ item.listingTitle }}</v-card-subtitle
                          >
                          <v-chip small class="ml-3 mb-1 mt-1">{{ item.neighborhood }}</v-chip>
                          <v-card-subtitle class="text-sm-body-custom font-weight-medium pa-0 pl-3"
                            >{{ item.occupancyRate }} Occupany Rate</v-card-subtitle
                          >
                          <v-card-subtitle class="text-sm-body-custom font-weight-medium pa-0 pl-3"
                            >{{ formatCurrency(item.annualRevenue) }} Estimated Income</v-card-subtitle
                          >
                          <v-card-subtitle class="text-sm-body-custom font-weight-medium pa-0 pl-3"
                            >{{ formatCurrency(item.publishNightlyRate) }} Nightly Rate</v-card-subtitle
                          >
                        </v-col>
                      </v-row>
                    </v-card>
                    <v-divider class="pa-2"></v-divider>
                  </div>
                </v-row>
                <v-row>
                  <v-col>
                    <v-pagination
                      v-model="pageNumProperties"
                      :length="Math.round(propertiesList.length / pagingPropertiesPerPage) - 1"
                      :total-visible="7"
                    ></v-pagination>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-navigation-drawer>
          <!---
          <v-row>
            <v-col>
              <v-radio-group v-model="mapType" class="pt-0 mt-0" row @change="changeMaps">
                <v-radio label="Street" value="street"></v-radio>
                <v-radio label="Light" value="light"></v-radio>
                <v-radio label="Dark" value="dark"></v-radio>
                <v-radio label="Street View" value="street-view"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          --->
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral';
import mapboxgl from 'mapbox-gl';
import { mapGetters } from 'vuex';
import StrDetail from '@/components/StrDetail';
import NeighborhoodsService from '@/services/neighborhoods.service';
import StrsService from '@/services/strs.service';

export default {
  name: 'WidgetExplorer',
  components: { StrDetail },
  data() {
    return {
      map: null,
      drawer: false,
      numeral,
      loadingPropertiesGeo: false,
      loadingPropertiesList: false,
      mapSearchTimer: '',
      activeParcel: null,
      propertiesGeo: [],
      propertiesList: [],
      propertiesFilters: [],
      propertiesFilterCount: 0,
      propertiesMapCount: 0,
      propertyDetail: null,
      propertyQuickSearch: [],
      propertiesCluster: [],
      propertiesLayers: { zone_overlays: { features: [] }, streets: { features: [] } },
      searchPropertiesText: '',
      textSearchTimer: '',
      parcelDialog: false,
      showContactInfo: false,
      contactList: [],
      mapType: 'dark',
      mapTile: 'dark-v10',
      search: '',
      tableKey: '1',
      searchPropertyListText: null,
      pageNumProperties: 1,
      pagingPropertiesPerPage: 6,
      maxZoom: 13,
      neighborhoods: [],
      neighborhoodId: '',
      neighborhoodGeos: [],
      loadingNeighborhoodCluster: false,
      markersOnScreen: {},
      markers: {},
    };
  },
  computed: {
    ...mapGetters({
      activeGeoId: 'getActiveGeoId',
    }),
    // quick search grid list
    paginateProperties() {
      var index = (this.pageNumProperties - 1) * this.pagingPropertiesPerPage;
      return this.propertiesList.slice(index, index + this.pagingPropertiesPerPage);
    },
  },
  watch: {
    activeGeoId() {
      this.loadMap();
    },
  },
  mounted() {
    this.getNeighborhoods();
    if (this.$store.state.activeGeoId) {
      this.loadMap();
    }
  },
  methods: {
    // Get detailed property
    getParcel(propId) {
      console.log(propId);
      var res = this.propertiesList.find((s) => s.propId === propId);
      console.log(res);
      this.$store.commit('setStrDetail', res);
      this.$store.commit('showStrDetail', true);
    },
    // Get all neighborhoods
    async getNeighborhoods() {
      NeighborhoodsService.getNeighborhoods()
        .then((response) => {
          this.neighborhoods = response.data;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => (this.errored = false));
    },
    // Select the neighborhood
    selectNeighborhood() {
      let neighborhood = this.neighborhoods.filter((item) => {
        return parseInt(item.id) === parseInt(this.neighborhoodId);
      });
      this.$store.commit('setNeighborhood', neighborhood[0]);
    },
    refreshData() {
      this.searchProperties();
      // may not want to call this twice
      this.getClusterNeighborhoods();
    },
    // main search properties method
    searchProperties() {
      // set map boundary
      this.setMapBoundary();
      // exit if zoomed out and only show clusters
      // turn back on if not showing dots
      if (this.map.getZoom() <= this.maxZoom) {
        this.getClusterNeighborhoods();
        return;
      }

      // only get map geo for map view
      this.updateMapParcels();
      this.loadingPropertiesList = true;
      StrsService.getStrs()
        .then((response) => {
          this.propertiesList = response.data;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
        })
        .finally(() => {
          this.loadingPropertiesList = false;
          this.tableKey = '12';
        });
    },
    // count properties
    getClusterNeighborhoods() {
      this.neighborhoodGeos = [];
      this.loadingNeighborhoodCluster = true;
      NeighborhoodsService.getNeighborhoodsGeo()
        .then((response) => {
          this.neighborhoodGeos = response.data;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
          this.loadingNeighborhoodCluster = false;
        })
        .finally(() => {
          this.updateClusters();
          this.errored = false;
          this.loadingNeighborhoodCluster = false;
        });
    },
    // Currency formatting
    formatCurrency(value) {
      return Number(parseFloat(value)).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    // Changing maps
    changeMaps() {
      if (this.mapType === 'street') {
        this.mapTile = 'streets-v11';
      } else if (this.mapType === 'light') {
        this.mapTile = 'light-v10';
      } else if (this.mapType == 'dark') {
        this.mapTile = 'dark-v10';
      } else if (this.mapType == 'street-view') {
        this.mapTile = 'dark-v10';
      }
      this.loadMap();
    },
    // Load initial map
    loadMap() {
      var vm = this;
      mapboxgl.accessToken =
        'pk.eyJ1IjoiY2hhcG1hc3dlbXBvd2VyIiwiYSI6ImNraDZ0Z2M5eDAyY2kyeGsxYno1enU1MGgifQ.P6K0qDTB7-ccH0OaD4K2hg';
      // Init map settings
      this.map = new mapboxgl.Map({
        container: 'mapdiv',
        style: 'mapbox://styles/mapbox/' + this.mapTile + '',
        center: this.$store.state.activeGeoLongLat,
        // pitch: 50, // pitch in degrees
        zoom: 13,
      });

      // Add map controls
      this.map.addControl(new mapboxgl.NavigationControl());
      // On a map move event, zoom, etc get new data
      this.map.on('moveend', (e) => {
        clearTimeout(this.mapSearchTimer);
        if (
          e.originalEvent != 'UIEvent' &&
          this.loadingPropertiesList == false &&
          this.loadingPropertiesGeo == false &&
          this.loadingNeighborhoodCluster == false
        ) {
          this.mapSearchTimer = setTimeout(function () {
            vm.searchProperties();
          }, 1000);
        }
      });

      // On map load event
      this.map.on('load', function () {
        // init map parcel data
        vm.searchProperties();
        // On click event show parcel details

        vm.map.on('click', 'parcels', function (e) {
          //console.log(e.features[0].properties);
          //var coordinates = e.features[0].geometry.coordinates;
          //vm.activeParcel = e.features[0].properties.publishNightlyRate;
          vm.getParcel(e.features[0].properties.propId);
          /*
            // Center map on button push
            vm.map.flyTo({
              center: e.features[0].geometry.coordinates[0][0].slice()
            });
            */

          /*
          // center popup over property
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          // Show mini popup to map
          new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(`<div>hello</div><div>${e.features[0].properties.propId}</div>`)
            .addTo(vm.map);
          */
        });

        // On mouseover add styles
        vm.map.on('mouseenter', 'parcels', function () {
          vm.map.getCanvas().style.cursor = 'pointer';
        });

        // Change it back to a pointer when it leaves.
        vm.map.on('mouseleave', 'parcels', function () {
          vm.map.getCanvas().style.cursor = '';
        });
      });
    },
    // Neighborhood cluster
    updateClusters() {
      var vm = this;
      // Add markers to the map.
      for (const marker of this.neighborhoodGeos.features) {
        var multiplier = marker.properties.num_properties.toString().length;
        var innerSize = multiplier * 14 + 'px';
        var innerMargin = multiplier * 7 * -1 + 'px';
        var outerSize = multiplier * 20 + 'px';
        var paddingTop = multiplier * 4 + 'px';
        var propCount = this.numeral(marker.properties.num_properties).format('0,0');
        // Create a DOM element for each marker.
        const el = document.createElement('div');
        el.className = 'marker';
        el.innerHTML = `<div><div class="marker-circle-outer" style="width:${outerSize};height:${outerSize}"><div class="marker-circle-inner" style="width:${innerSize};height:${innerSize};margin: ${innerMargin} 0px 0px ${innerMargin};padding-top: ${paddingTop}">${propCount}</div></div><div class="marker-circle-label">${marker.properties.name}</div></div>`;
        el.addEventListener('click', () => {
          vm.map.flyTo({
            center: marker.geometry.coordinates,
            zoom: 14,
          });
        });
        new mapboxgl.Marker(el).setLngLat(marker.geometry.coordinates).addTo(vm.map);
      }
    },

    // update map with new data as the user moves the map
    updateMapParcels() {
      /*
      for (var i = marker.length - 1; i >= 0; i--) {    
        marker[i].remove();
      } 
      */
      // open drawer
      //this.drawer = false;

      var vm = this;
      vm.loadingPropertiesGeo = true;
      vm.propertiesGeo = [];

      // Get str geo placements
      StrsService.getStrsGeo()
        .then((response) => {
          vm.propertiesGeo = response.data.features;
          vm.propertiesMapCount = vm.propertiesGeo.length;
        })
        .catch((error) => {
          console.log(error), (this.errored = true);
          this.loadingPropertiesGeo = false;
        })
        .finally(() => {
          const newMarkers = {};
          for (const markerData of vm.propertiesGeo) {
            console.log(markerData.properties.publishNightlyRate_index);
            const id = markerData.properties.propId;
            // Create a DOM element for each marker.
            const el = document.createElement('div');
            var bgColor = '';
            if (markerData.properties.publishNightlyRate_index == '1') {
              bgColor = 'RED';
            } else if (markerData.properties.publishNightlyRate_index == '2') {
              bgColor = 'YELLOW';
            } else {
              bgColor = 'GREEN';
            }
            el.innerHTML = `<div class="small-marker-circle-outer"><div class="small-marker-circle-inner" style="background: ${bgColor}"></div><div class="small-marker-circle-label">${markerData.properties.publishNightlyRate}</div></div>`;
            /*
              el.addEventListener('click', () => {
                vm.map.flyTo({
                  center: marker.geometry.coordinates,
                  zoom: 14,
                });
              });
            */
            let marker = vm.markers[id];
            if (!marker) {
              marker = vm.markers[id] = new mapboxgl.Marker(el).setLngLat(markerData.geometry.coordinates);
            }

            newMarkers[id] = marker;
            if (!vm.markersOnScreen[id]) {
              marker.addTo(vm.map);
            }
          }
          // for every marker we've added previously, remove those that are no longer visible
          for (const id in vm.markersOnScreen) {
            if (!newMarkers[id]) {
              vm.markersOnScreen[id].remove();
            }
          }
          vm.markersOnScreen = newMarkers;

          /*
          var mapLayer = vm.map.getLayer('parcels');
          if (typeof mapLayer !== 'undefined') {
            vm.map.getSource('parcels').setData(vm.propertiesGeo);
          } else {
            vm.map.addSource('parcels', {
              type: 'geojson',
              data: vm.propertiesGeo,
            });

            // add str points
            vm.map.addLayer({
              id: 'parcels',
              type: 'symbol',
              source: 'parcels',
              minzoom: 12,
              layout: {
                'text-field': ['get', 'publishNightlyRate'],
                'text-size': 12,
              },
              paint: {
                'text-color': '#ffffff',
              },
            });
            
          }
          */
          this.loadingPropertiesGeo = false;
        });
    },
    // update map with new data as the user moves the map
    setMapBoundary() {
      if (this.map) {
        var $bounds = this.map.getBounds();
        var southWest = new mapboxgl.LngLat($bounds._sw.lng, $bounds._sw.lat);
        var northEast = new mapboxgl.LngLat($bounds._ne.lng, $bounds._ne.lat);
        var boundingBox = new mapboxgl.LngLatBounds(southWest, northEast);
        var northWest = boundingBox.getNorthWest();
        var southEast = boundingBox.getSouthEast();
        var mapBoundary = [];
        mapBoundary.push([parseFloat(northWest.lng.toFixed(5)), parseFloat(northWest.lat.toFixed(5))]);
        mapBoundary.push([parseFloat($bounds._ne.lng.toFixed(5)), parseFloat($bounds._ne.lat.toFixed(5))]);
        mapBoundary.push([parseFloat(southEast.lng.toFixed(5)), parseFloat(southEast.lat.toFixed(5))]);
        mapBoundary.push([parseFloat($bounds._sw.lng.toFixed(5)), parseFloat($bounds._sw.lat.toFixed(5))]);
        mapBoundary.push([parseFloat(northWest.lng.toFixed(5)), parseFloat(northWest.lat.toFixed(5))]);
        this.$store.commit('setMapBoundary', mapBoundary);
      } else {
        this.$store.commit('setMapBoundary', []);
      }
    },
  },
};
</script>
<style scoped>
.text-sm-body-custom {
  font-size: 0.775rem;
  line-height: 14px;
}
</style>
