import Vue from 'vue';
import VueRouter from 'vue-router';
import WidgetExplorer from '@/views/WidgetExplorer.vue';
import WidgetExplorerCluster from '@/views/WidgetExplorerCluster.vue';
import WidgetExplorerHeatMap from '@/views/WidgetExplorerHeatMap.vue';
import STRGrid from '@/views/STRGrid.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'widgetexplorercluster',
      component: WidgetExplorerCluster,
    },
    {
      path: '/widget',
      name: 'widgetexplorer',
      component: WidgetExplorer,
    },
    {
      path: '/widgetheatmap',
      name: 'widgetexplorerheatmap',
      component: WidgetExplorerHeatMap,
    },
    {
      path: '/str/grid',
      name: 'strgrid',
      component: STRGrid,
    },
  ],
});

Vue.router = router;

export default router;
